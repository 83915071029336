.card {
  width: 100%;
  max-width: 420px;
  box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.17);
  box-sizing: border-box;
  border-radius: 8px;
  background: #fff;
  position: relative;
  z-index: 10;
}

.container {
  padding: 2.5em;
}

.welcomeContainer {
  text-align: center;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
}

.subtitle {
  text-transform: uppercase;
  padding: 1em 0;
  font-size: 18px;
}
