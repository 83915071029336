.input {
  background: var(--primary-white);
  border: 1px solid #dadee6;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
  padding: 0.5em 0.8em;
}

.inputContainer {
  display: flex;
}

.label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #494a4d;
  margin: 0;
}

.iButton {
  position: absolute;
  width: 42px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: var(--primary-white);
  border: 1px solid #dadee6;
  box-sizing: border-box;
  border-radius: 0px 8px 8px 0px;
}

.icon {
  position: relative;
  width: 42px;
  height: 42px;
  left: 5px;
  top: 0px;
  bottom: 0px;
  padding-top: 8px;
  padding-left: 1px;
  background: var(--primary-white);
  font-size: 16px;
  border: 1px solid #dadee6;
  text-align: center;
  box-sizing: border-box;
  color: #181b20;
  border-radius: 8px 0px 0px 8px;
}

.button {
  background-color: var(--primary-color);
  border-radius: 8px;
  padding: 12px 24px;
}

.buttonLabel {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.resetPassword {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #aaadb3;
}
