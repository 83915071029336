.text {
    fill: #333;
    font-size: 24px;
}

.valueText {
    fill: #333;
    font-family: Roboto Mono;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 47px;
    text-align: center;
}

.seperatorText {
    fill: #555;
    font-size: 14px;
}

.maxText {
    fill: #999;
    font-size: 14px;
}

.unitText {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    fill: #797B80;
}