.page-header {
  position: fixed;
  top: 0;
  z-index: 4;
  left: 0;
  right: 0;
  border-bottom-width: 1px;
  width: 100%;
  height: var(--header-height);
  background-color: #fff;
  padding: 0 2rem;
}

.page-header .header {
  color: var(--primary-color);
  font-weight: bold;
}

.page-header .menu {
  font-weight: bold;
  border: 1px solid #dadee6;
  border-radius: 8px;
  color: #333;
}

.page-header .menu p {
  padding: 8px 16px;
  border-right: 1px solid #dadee6;
  border-left: 1px solid #dadee6;
}

.page-header .menu .svg-wrapper {
  padding: 0 8px;
}

.page-header .search-input .search-input-icon {
  border-left: 1px solid #dadee6 !important;
}

.page-header .search-input input {
  border-radius: 8px;
}

@media (min-width: 900px) {
  .mobile-menu-toggle {
    display: none !important;
  }
}
