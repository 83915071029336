.table-button {
  width: 80px;
  height: 40px;
  top: 0px;
  background: #80af5a;
  border-radius: 4px;
  color: #ffffff;
  align-items: center;
  text-align: center;
  position: static;
}

.table-label {
  position: static;
  height: 21px;

  /* Primary / White */

  background: #ffffff;
  /* Neutrals/900 */

  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 8px;

  color: #000000;
  align-items: center;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
}

.Table__pagination {
  display: flex;
  margin-right: 50px;
}

.Table__pageButton {
  background-color: #fff;
  margin-left: 5px;
  margin-right: 5px;
  width: 28px;
  height: 28px;
  color: #171717;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  /* or 200% */

  align-items: center;
  text-align: center;
}

.Table__pageButton--active {
  background-color: #c4c4c4;
  color: #ffffff;
}

table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 1100px;
}

table tr {
  border: 1px solid #d9dee5;
}

table td,
table th {
  text-align: left;
}

table td a,
table td span {
  padding: 1.2em 1em;
  display: block;
}

table tbody tr {
  font-family: Work Sans;
  font-size: 14px;
  color: #494a4d;
}

table tbody tr:hover {
  background-color: #f6f6f6;
  box-shadow: inset 0px -1px 0px #e9500e;
}

table th {
  border-top: 1px solid #e7e7ed;
  color: #494a4d;
  font-weight: 600;
  height: 48px;
  font-size: 14px;
  padding: 1em;
}

.pagination {
  text-align: center;
}

.pagination button {
  padding-left: 5px;
  padding-right: 5px;
}
