.card {
  max-width: 220px;
  color: #797b80;
  height: 134px;
  background: #ffffff;
  border: 1px solid #dadee6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1em;
}

.invisibleCard {
  max-width: 220px;
  color: #797b80;
  max-height: 134px;
  box-sizing: border-box;
  border-radius: 8px;
}

.cardBigTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 5px;
  color: #333333;
}

.cardDesc {
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #494a4d;
}

.cardNumber {
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 53px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 1px;
  color: #333333;
}

.cardTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #797b80;
}
