html {
  height: 100%;
}
body {
  min-height: 100%;
}

body {
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* #root {
  display: flex;
} */

/* @media (max-width: 900px) {
  #root {
    flex-direction: column;
    padding-top: var(--header-height);
  }
} */
