.main-container {
  margin-top: var(--header-height);
  flex: 1;
}

.content {
  padding: var(--main-content-padding);
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
  border-color: #e9500e !important;
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.2) !important;
}
.main-content .main-content-loader {
  position: absolute;
  right: var(--main-content-padding);
  animation: loaderFadeIn 2s;
}

@keyframes loaderFadeIn {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ml__facility-info-text {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  display: flex;
  align-items: center;

  color: #000000;
}

.ml__facility-info-label {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.5);
}

sup {
  width: 3px
}

@media (max-width: 900px) {
  .main-container {
    margin-top: 0;
  }
}
