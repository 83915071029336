.sidebar {
  background: #404040;
  padding: 1.4rem;
  padding-top: var(--header-height);
  min-height: 100vh;
  min-width: var(--sidebar-width);
  font-weight: 400;
}

.sidebar:before {
  content: '';
  height: 1.4rem;
}

.button {
  color: white;
  text-align: left;
  padding: 1rem 1rem;
  cursor: pointer;
  border-radius: 8px;
  height: 56px;
  margin-bottom: 0.4em;
  transition: background 0.2s;
}

.buttonActive {
  background: #393539;
}

.button:hover {
  background: #393539;
}

@media (max-width: 900px) {
  .sidebar {
    min-height: auto;
    padding-top: 0;
  }

  body:not(:global(.show-mobile-menu)) .sidebar {
    display: none;
  }
}
